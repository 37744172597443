<div class="w-100 h-100 d-flex flex-column">
    <div class="d-grid gap-2 mt-4 mx-2">
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='news'><i class="bi bi-newspaper"></i><span class="ms-2 d-none d-md-inline">Notizie</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='ads'><i class="bi bi-geo-alt-fill"></i><span class="ms-2 d-none d-md-inline">Luoghi</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='ads'><i class="bi bi-geo"></i><span class="ms-2 d-none d-md-inline">Itinerari</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='ads'><i class="bi bi-map"></i><span class="ms-2 d-none d-md-inline">Guide</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='ads'><i class="bi bi-award"></i><span class="ms-2 d-none d-md-inline">Concorsi</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='ads'><i class="bi bi-controller"></i><span class="ms-2 d-none d-md-inline">Giochi</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='ads'><i class="bi bi-question-square"></i><span class="ms-2 d-none d-md-inline">Quiz</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='ads'><i class="bi bi-gear"></i><span class="ms-2 d-none d-md-inline">Impostazioni</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='ads'><i class="bi bi-person-circle"></i><span class="ms-2 d-none d-md-inline">Account</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="logout()"><i class="bi bi-box-arrow-right"></i><span class="ms-2 d-none d-md-inline">Logout</span></button>
    </div>
</div>