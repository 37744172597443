import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { _DEBUG } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { StateService } from '../../services/state.service';
import { StoreFilesService } from '../../services/store-files.service';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {
  state: string = '';
  constructor(private fb: FormBuilder,
              private stateService:StateService,
              private storeFilesService:StoreFilesService) {
  }

  @Input('validFilesName') validFilesName: string[] | undefined;
  @Input('validFilesExt') validFilesExt: string[] | undefined;
  @Input('fileBaseDir') fileBaseDir: string = environment.FILES;
  @Input('uploadDir') uploadDir: string = '';
  @Input('files') files: {name:string,path:string}[] = [];
  @Output() filesChange:EventEmitter<{name:string,path:string}[]> = new EventEmitter<{name:string,path:string}[]>();
  ngOnInit(): void {
  }

  public uploadedFiles: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    
    console.log(files)
    //this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          if(this.validFilesName && this.validFilesName.includes(file.name) && !this.files.find(f=>{return f.name == file.name})) {
            this.storeFilesService.saveFilesToPath([file],this.uploadDir).subscribe((result:any[])=>{
              result.forEach(({filePath})=>{
                  if(filePath.includes(file.name)){
                  this.stateService.message$.next({text:`Hai allegato ${file.name}`,type:environment.MESSAGES.SUCCESS})
                  this.files.push({name:file.name,path:filePath});
                  if(_DEBUG) console.log(this.fileBaseDir+filePath);
                  this.filesChange.emit(this.files);
                  //this.files.push(file.name);
                  //if(file.name == 'doc.pdf') this.docOK = true;
                  //if(file.name == 'visura.pdf') this.visOK = true;
                }            
              })
            })
          }

          /*console.log(droppedFile.relativePath, file);
          if(file.name == 'doc.pdf' || file.name == 'visura.pdf') {
            this.uploadedFiles.push(droppedFile);
            
          }*/
          else{
            let message = '';
            if(this.files.find(f=>{return f.name == file.name})){
              message = 'Il file è gia presente.'
            }else{
              message = 'Il nome del file non è corretto.';
            }
            this.stateService.message$.next({text:message,type:environment.MESSAGES.ERROR})
          }
          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)

          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/


        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        if(_DEBUG) console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event:any){
    if(_DEBUG) console.log(event);
  }

  public fileLeave(event:any){
    if(_DEBUG) console.log(event);
  }

  public fileUploadSubmit(){
    this.state = 'done';
  }
}
