<div class="w-100 position-relative header d-flex flex-row align-items-center fixed-top">
    <div class="mw-25 ms-4" routerLink='/'>
        <img class="" src="/assets/logo.png" alt="" style="height:8vh;max-height:80px;min-height:60px;width:auto;">
    </div>
    <!--div class="ms-auto fs-1 me-4"><i class="bi bi-qr-code-scan"></i></div-->
    <div class="ms-auto me-1 d-none d-md-block fs-6">{{(stateService.user$|async)?.email}}</div>
    <div class="btn btn-light fs-1 ms-auto ms-md-1 me-1" type="button" routerLink='/scanQR'><i class="bi bi-qr-code-scan"></i></div>
    <div class="btn btn-light fs-1 d-md-none me-1" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"><i class="bi bi-list"></i></div>
</div>
<!--div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div class="card">
      <app-side-menu class="side-menu"></app-side-menu>
    </div>
  </div-->
<app-side-menu class="side-menu" #collapse="ngbCollapse" (collapseMenu)="collapse.toggle()" [(ngbCollapse)]="isCollapsed">
</app-side-menu>