import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { NgxImageCompressService, DataUrl, UploadResponse } from "ngx-image-compress";
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { StoreImgsService } from '../../services/store-imgs.service';
import { DeleteStoredImgsService } from '../../services/delete-stored-imgs.service';
import { _DEBUG } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-multi-img-modal',
  templateUrl: './multi-img-modal.component.html',
  styleUrls: ['./multi-img-modal.component.css']
})
export class MultiImgModalComponent implements OnInit, AfterViewInit {
  @Input('uploadDir') uploadDir: string = '';
  @Input('imagesBaseDir') imagesBaseDir:string = environment.IMAGES;
  @Input() multiImage: boolean = true;
  @Input() images: string[] = [];
  @Output() imagesChange = new EventEmitter<string[]>();
  
  activeSlide: number = 0;
  selectedFiles?: FileList;
  previews: string[] = [];
  imgResultMultiple: UploadResponse[] = [];
  imgResultAfterCompress: DataUrl = '';
  addedImagesIndex:any[] = [];

  constructor(public activeModal: NgbActiveModal,
    private storeImagesSvc: StoreImgsService,
    private deleteImageService: DeleteStoredImgsService,
    private imageCompress: NgxImageCompressService,
    private modalService: NgbModal,
    private stateService: StateService
  ) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    if(_DEBUG) console.log(this.images);
    
  }

  setActiveSlide(i: number) {
    this.activeSlide = i;
  }

  imgsInputClick() {
    this.uploadMultipleFiles();
  }

  cancel() {
    this.addedImagesIndex.forEach(i => {
      this.deleteImg(i);
    })
    this.activeModal.dismiss();
  }

  deleteImg(i: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'image';
    modalRef.result.then(() => {
      if(_DEBUG) console.log('confirmed')
      //let files = i ? [this.images[i]] : this.images;
      const deleteImg$ = this.deleteImageService.deleteImgs([this.images[i]]);
      deleteImg$.subscribe(data => {
        if(_DEBUG) console.log(`delete image msg: ${data}`);
        this.images.splice(i, 1);
        this.setActiveSlide(i==0?0:i-1);
        this.addedImagesIndex.splice(this.addedImagesIndex.indexOf(i), 1);
        //this.imagesChange.emit(this.images);
        this.stateService.message$.next({text:'Immagine eliminata.',type:environment.MESSAGES.WARNING})
      });
    })
  }

  uploadMultipleFiles() {
    return this.imageCompress
      .uploadMultipleFiles()
      .then((multipleOrientedFiles: UploadResponse[]) => {
        this.imgResultMultiple = multipleOrientedFiles;
        console.warn(`${multipleOrientedFiles.length} files selected`);
        this.imgResultMultiple.forEach((img) => {
          if(_DEBUG) console.log(this.getImgProps(img.image))
          console.warn(
            'Size in bytes was:',
            this.imageCompress.byteCount(img.image)
          );
          this.imageCompress
            .compressFile(img.image, img.orientation, 50, 50)
            .then((result: DataUrl) => {
              this.imgResultAfterCompress = result;
              console.warn(
                'Size in bytes is now:',
                this.imageCompress.byteCount(result)
              );
              const saveImgs$ = this.storeImagesSvc.saveImgsToPath({ files: [result], path: this.uploadDir }).pipe(take(1));
              saveImgs$.subscribe(filePath => {
                if(_DEBUG) console.log(<any[]>filePath);
                filePath.forEach((fp:any) => {
                  this.addedImagesIndex.push(this.images.push(fp['filePath']) - 1);
                  this.stateService.message$.next({text:'Immagine salvata',type:environment.MESSAGES.SUCCESS});
                })
              })
            });
        });
      });
  }

  getImgProps(base64:string){
    let props = base64.substring(0,base64.indexOf(';'));
    let type = props.substring(props.indexOf(':')+1,props.indexOf('/'))
    let ext = props.substring(props.indexOf('/')+1);
    return {type:type,ext:ext}
  }

}
