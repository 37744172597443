import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/models/user';


@Injectable({
  providedIn: 'root'
})
export class StateService {

  public lgScreen$ = new ReplaySubject<boolean>(1);
  public user$ = new BehaviorSubject<User>({});
  public message$ = new ReplaySubject<{text:string,type:string}>(1);
  
  constructor(private responsive: BreakpointObserver,
              private http: HttpClient) {
    this.responsive.observe('(min-width: 1200px)')
      .subscribe(result => {
        if (result.matches) {
          this.lgScreen$.next(true);
        } else {
          this.lgScreen$.next(false);
        }
      });
  }
}