import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import * as Session from "supertokens-auth-react/recipe/session";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  constructor(private auth: AuthService) { }
  @Output() collapseMenu: EventEmitter<void> = new EventEmitter();
  ngOnInit(): void {
  }

  closeSideMenu() {
    this.collapseMenu.emit()
  }

  async logout() {
    await Session.signOut();
    window.location.reload();

  }
}
